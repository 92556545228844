<template>
  <div class="auth">
    <div class="auth_card">
      <h2>Melinda et Jeremy</h2>
      <label for="mots de passe"></label>
      <input type="password" placeholder="Vous nous dites oui ?" @keyup.enter="Login" v-model="password">
      <div class="auth_card_error">
        <p v-show="echecOn" ref="errorMessage" class="errorMessage">{{ errorMessageText }}</p>
      </div>
      <button @click="Login"><font-awesome-icon icon="heart" /></button>            
    </div>
    <div class="background">
      <div class="bean element" v-for="i in 15" :key="i"></div>
      <div class="the element" v-for="i in 15" :key="i"></div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';

export default {
  name: "AuthView",
  data() {
    return {
      password: "",
      echecOn: false,
      compteur: 0,
      dictWords: [
        "Desolé mauvais mot de passe ...",
        "Tiens encore une faute de frappe ?",
        "C'est bizarre ce que tu essaies de faire !",
        "Aller vas-y, crack moi si tu peux :)"
      ],
      errorMessageText: ""
    }
  },
  mounted() {
    if (this.$route.query.token) {
      this.AllowConnection()
    }
  },
  methods: {
    AllowConnection() {
      const token = this.$route.query.token;
      console.log(token)
      if (token === process.env.APP_KEY) {
        localStorage.setItem("auth", token);

        // 🔹 Vérifie si une redirection est demandée
        const redirectPath = this.$route.query.redirect;
        if (redirectPath) {
          this.$router.replace(redirectPath);
        } else {
          this.$router.push("/");
        }
      }
    },
    Login() {
      const input = this.password;

      if (input == process.env.ADMIN_PASSWORD || input == process.env.APP_PASSWORD) {
        localStorage.setItem('auth', process.env.APP_KEY);
        localStorage.setItem('admin', input == process.env.ADMIN_PASSWORD);
        
        // 🔹 Vérifie si un redirect est présent dans l’URL
        const redirectPath = this.$route.query.redirect;

        if (redirectPath) {
          this.$router.replace(redirectPath); // ✅ Redirige après connexion
        } else {
          this.$router.push('/');
        }
      } else {
        this.echecOn = true;
        this.animateErrorMessage();
        setTimeout(() => {
          this.echecOn = false;
        }, 3000);
      }
    },
    animateErrorMessage() {

      const errorMessage = this.$refs.errorMessage
      if (this.compteur < this.dictWords.length) {
        errorMessage.innerText = this.dictWords[this.compteur]
      } else {
        errorMessage.innerText = this.dictWords[this.dictWords.length - 1]
      }
      
      this.compteur += 1

      gsap.fromTo(errorMessage, 
        { x: 0, color: "red", opacity: 1 }, 
        { 
          x: 10, 
          duration: 0.5, 
          ease: "power1.inOut",
          yoyo: true, 
          repeat: 3, 
        })
      gsap.to(errorMessage, 
        {
          opacity: 0, 
          delay: 2,
          duration: 1,
          colro: "black"
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
$range-count: 30;
$sway-type: "sway-left-to-right", "sway-right-to-left";

@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}

@function sample($list) {
  @return nth($list, random(length($list)));
}

.auth {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $BrownShade3;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &_card {
    position: relative;
    z-index: 101;
    width: 90%;
    max-width: 500px;
    border-radius: 22px;
    background: linear-gradient(145deg, #d2af95, #b0947d);
    box-shadow: 10px 10px 20px #a78b76, -10px -10px 20px #e1bda0;
    display: flex;
    flex-direction: column;
    align-items: center;
    &_error {
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    input {
      background: #C4A48B;
      background-color: #C4A48B;
      box-shadow: inset 10px 10px 20px #a78b76, inset -10px -05px 20px #e1bda0;
      border: none;
      height: 50px;
      width: 80%;
      text-align: center;
      border: 0;
      outline: 0;
      font-size: 16px;
      border-radius: 15px;
      padding: 16px;
      max-width: 500px;
    }
    input::placeholder {
      font-family: "Sarlotte";
      text-shadow: 1px 1px 0 #a78b76;
      color: $GreenShade2;
    }
    button {
      border-radius: 22px;
      background: linear-gradient(145deg, #d2af95, #b0947d);
      box-shadow: 10px 10px 20px #a78b76, -10px -10px 20px #e1bda0;
      width: 60px;
      height: 60px;
      border: none;
      margin: 25px;
      color: $GreenShade1;
      transition: 0.2s;
    }
    button:active {
      box-shadow: inset 10px 10px 20px #a78b76,
      inset -10px -10px 20px #e1bda0
    }
    h2 {
      font-size: 3em;
    }
  }

  .background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    .element {
      position: absolute;
      left: var(--bubble-left-offset);
      bottom: -30%;
      display: block;
      width: var(--bubble-radius);
      height: var(--bubble-radius);
      background-size: cover;
      background-repeat: no-repeat;
      animation: rotateFlip var(--bubble-rotate-duration) var(--bubble-rotate-delay) linear infinite, float-up var(--bubble-float-duration) var(--bubble-float-delay) ease-in infinite;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        animation: var(--bubble-sway-type) var(--bubble-sway-duration) var(--bubble-sway-delay) ease-in-out alternate infinite;
      }

      @for $i from 1 through $range-count {
        &:nth-child(#{$i}) {
          --bubble-left-offset: #{random_range(0vw, 100vw)};
          --bubble-radius: #{random_range(1vw, 10vw)};
          --bubble-float-duration: #{random_range(12s, 24s)};
          --bubble-sway-duration: #{random_range(4s, 6s)};
          --bubble-float-delay: #{random_range(0s, 4s)};
          --bubble-sway-delay: #{random_range(0s, 4s)};
          --bubble-sway-type: #{sample($sway-type)};
          --bubble-rotate-duration: #{random_range(25s, 35s)}; /* Added rotate duration */
          --bubble-rotate-delay: #{random_range(0s, 4s)}; /* Added rotate delay */
        }
      }
    }

    .the {
      background-image: url("../assets/feuille_the.png"); /* Path to your coffee bean image */
    }

    .bean {
      background-image: url("../assets/grain_cafe.png"); /* Path to your coffee bean image */
    }
  }
}

@keyframes float-up {
  to {
    transform: translateY(-175vh);
  }
}

@keyframes sway-left-to-right {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(100%);
  }
}

@keyframes sway-right-to-left {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes rotateFlip {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia';

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faPhone, faEnvelope, faHeart, faTree, faGamepad, faWineBottle, faGlassMartini, faGlasses } from '@fortawesome/free-solid-svg-icons'
import { faSpotify } from '@fortawesome/free-brands-svg-icons'

library.add(faPhone, faEnvelope, faHeart, faSpotify, faTree, faGamepad, faWineBottle, faGlassMartini, faGlasses)

const pinia = createPinia();

createApp(App)
    .use(pinia)
    .use(router)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')

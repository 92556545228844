import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '@/views/HomeView.vue'
import MariageView from '@/views/MariageView.vue'
import NotaBeneView from '@/views/NotaBeneView.vue'
import SayYesView from '@/views/SayYesView.vue'
import GamesView from '@/views/GamesView.vue'
import AuthView from '@/views/AuthView.vue'
import DrinksView from '@/views/DrinksView.vue'
import TempoView from '@/views/TempoView.vue'
import ExtraOrdinaireView from '@/views/ExtraOrdinaireView.vue'
import ExtraOrdinaireComponent from '@/components/ExtraOrdinaireComponent.vue'

const routes = [
  {path: '/', name: 'Home', component: HomeView},
  {path: '/mariage', name: "Le_Mariage", component: MariageView},
  {path: "/notabene", name: "Nota_Bene", component: NotaBeneView},
  {path: "/sayyes", name: "Say_yes", component: SayYesView},
  {path: "/games", name: "Games", component: GamesView},
  {path: "/games/whois", name: "WhoIs", component: ExtraOrdinaireView},
  {path: "/games/whois/:id", name: "WhoIsDetail", component: ExtraOrdinaireComponent},
  {path: "/drinks", name: "Drinks", component: DrinksView},
  {path: '/auth', name: 'Auth', component: AuthView},
  {path: '/tempo', name: 'Tempo', component: TempoView},
  {path: '/:catchAll(.*)', redirect: { name: 'Home' }},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
})

// Gardien global pour l'authentification
router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("auth");

  // 🔹 Si l'utilisateur est déjà authentifié, on le laisse passer
  if (isAuthenticated == process.env.APP_KEY) {
    next();
    return;
  }

  // 🔹 Vérifie si un token est fourni dans l'URL
  if (to.query.token && to.query.token === process.env.APP_KEY) {
    localStorage.setItem("auth", process.env.APP_KEY);
    
    // 🔹 Redirection vers la page demandée sans le token visible
    delete to.query.token; // Supprime directement le token de l'URL
    next({ path: to.path, query: to.query, replace: true });
    return;
  }

  // 🔹 Si aucune authentification, redirige vers la page Auth
  if (to.name !== "Auth") {
    next({ name: "Auth", query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router

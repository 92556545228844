import { defineStore } from "pinia";

// Données des boissons
const hardsData = [
    {
        country: "Vietnam",
        type: "Whisky",
        name: "Atsushi",
        alcool: 40.5,
        description: "Fusion délicate entre tradition et modernité, l’Atsushi Blend du Vietnam offre des notes suaves de fruits tropicaux, de caramel doré et d’épices douces, évoquant la richesse des terres vietnamiennes et le raffinement des savoir-faire asiatiques.",
        id: "VN",
        position: { x: 78, y: 63 },
        image: require("@/assets/bottle/Atsushi.png"),
    },
    {
        country: "Finlande, Isokyrö",
        type: "Whisky",
        name: "Kyrö",
        alcool: 47.5,
        description: "Né des brumes nordiques, le Kyrö Malt Rye dévoile des arômes chaleureux de seigle épicé, mêlés à des notes douces de caramel, de miel et de fruits mûrs, offrant une expérience audacieuse et veloutée, ancrée dans la pureté finlandaise.",
        id: "FI",
        position: { x: 50, y: 28 },
        image: require("@/assets/bottle/Kyrö.png"),
        url: "https://www.whisky.fr/kyro-rye-malt.html"
    },
    {
        country: "Inde, Ville de Bangalore",
        type: "Whisky",
        name: "Amrut",
        alcool: 46,
        description: "Forgé sous le soleil brûlant de Bangalore, l’Amrut Single Malt exhale des arômes riches et épicés, mêlant la chaleur des fruits tropicaux à des notes de miel et de chêne, pour une expérience intense et soyeuse aux accents exotiques.",
        id: "IN",
        position: { x: 61, y: 60 },
        image: require("@/assets/bottle/Amrut.png"),
        url: "https://www.whisky.fr/amrut-indian-single-malt-of.html"
    },
    {
        country: "Italie, Val Venosta",
        type: "Whisky",
        name: "Puni",
        alcool: 43,
        description: "Né au cœur des Alpes italiennes, le Puni PURE dévoile une élégance douce et céréalière, où les notes de malt doré s'entrelacent avec des touches subtiles de vanille et de fruits secs, offrant un voyage raffiné entre tradition alpine et modernité.",
        id: "IT",
        position: { x: 50, y: 61 },
        image: require("@/assets/bottle/Puni.png"),
    },
    {
        country: "Guadeloupe & Martinique",
        type: "Rhum",
        name: "Rum&Cane",
        alcool: 43,
        description: "Échappée sauvage entre la mer et les volcans, le Rum & Cane French Overseas marie des notes exotiques de fruits mûrs, de canne fraîche et de vanille boisée, offrant une élégance vibrante empreinte des terroirs ensoleillés d’outre-mer.",
        id: "GP",
        position: { x: 33, y: 59 },
        image: require("@/assets/bottle/Rum&Cane.png"),
    },
    {
        country: "Martinique",
        type: "Rhum",
        name: "Bally",
        alcool: 45,
        description: "Vieilli sous le soleil des Antilles, le Bally 7 ans révèle des arômes profonds de fruits confits, d’épices douces et de bois précieux, offrant une harmonie chaleureuse où s’entrelacent tradition créole et élégance tropicale.",
        id: "MQ",
        position: { x: 25, y: 70},
        image: require("@/assets/bottle/Bally.png"),
    },
];

const coffeeData = [
    {
        country: "Jamaïque, Region du Blue Mountains",
        name: "Blue Montaine",
        description: "Né des brumes jamaïcaines, le café Blue Mountain offre une douceur veloutée aux notes florales et chocolatées, évoquant la quiétude des montagnes et la fraîcheur des forêts.",
        id: "JM",
        position: { x: 23, y: 60 },
        image: require("@/assets/coffee/Blue_mountain.png"),
        url: "https://www.terresdecafe.com/fr/cafes-en-grains-ou-moulus/62-cafe-blue-mountain.html",
    },
    {
        country: "Brésil, Ville de Maragogipe",
        name: "Maragogype",
        description: "Le café Maragogype, géant des grains, dévoile une tasse douce et légère, aux arômes subtils de fleurs et d’épices, mêlant élégance et exotisme en toute délicatesse.",
        id: "BR",
        position: { x: 26, y: 85 },
        image: require("@/assets/coffee/Maragogype.png"),
        url: "https://www.terresdecafe.com/fr/cafes-en-grains-ou-moulus/1877-finca-hartmann-maragogype-lave-legendes.html?srsltid=AfmBOooZIJfU3fatlLO99ofyepzPheBo3OCUFcRu9s3i1zumofobdtBV",
    },
    {
        country: "Indonésie, Île de Java",
        name: "Java",
        description: "Né des terres volcaniques d’Indonésie, le café Java séduit par ses arômes profonds et épicés, où des notes boisées et terreuses s’entrelacent avec une douceur subtile.a",
        id: "ID",
        position: { x: 70, y: 77 },
        image: require("@/assets/coffee/Java.png"),
        url: "https://www.graindecafe.com/products/cafe-moulu-indonesie-java-radiophare?variant=49752582979744&country=FR&currency=EUR&utm_medium=product_sync&utm_source=google&utm_content=sag_organic&utm_campaign=sag_organic&utm_source=google&utm_medium=cpc&utm_campaign=shopping.cafe&gad_source=1&gclid=CjwKCAiA7Y28BhAnEiwAAdOJUAq6IxH_zH_k2Dk8YaysdB1vRIrn03y5dFZ-llfBDj0GNjt5nO6IxhoCYEIQAvD_BwE",
    },
    {
        country: "Éthiopie, Plateau de Gesha",
        name: "Geisha",
        description: "Le café Geisha enchante par ses arômes floraux et fruités, mêlant des notes de jasmin, d’agrumes et de miel, offrant une expérience délicate et raffinée, digne des terres d’altitude qui l’ont vu naître.",
        id: "ET",
        position: { x: 53, y: 60 },
        image: require("@/assets/coffee/Geisha.png"),
        url: "https://www.terresdecafe.com/fr/les-coffrets-cafes/1716-coffret-geisha-3700926150717.html",
    },
]

const teaData = [
    {
        country: "Inda, Collines de Darjeeling",
        name: "Darjeeling",
        description: "Né des brumes jamaïcaines, le café Blue Mountain offre une douceur veloutée aux notes florales et chocolatées, évoquant la quiétude des montagnes et la fraîcheur des forêts.",
        id: "IN",
        position: { x: 61, y: 54 },
        image: require("@/assets/tea/Darjeeling.png"),
        url: "https://www.mariagefreres.com/fr/catalogsearch/result/?q=darjeeling",
    },
    {
        country: "Japon",
        name: "Matcha",
        description: "Poudre d’émeraude, le Matcha déploie une amertume douce et végétale, mêlant intensité et sérénité dans un tourbillon soyeux.",
        id: "JP",
        position: { x: 88, y: 50 },
        image: require("@/assets/tea/Matcha.png"),
        url: "https://www.mariagefreres.com/fr/catalogsearch/result/?q=matcha",
    },
    {
        country: "Afrique du Sud",
        name: "Rooiboos",
        description: "Infusion solaire, le Rooibos révèle des saveurs douces et boisées, aux accents de miel et de vanille, évoquant les terres rouges d’Afrique.",
        id: "ZA",
        position: { x: 50, y: 76 },
        image: require("@/assets/tea/Rooiboos.png"),
        url: "https://www.mariagefreres.com/fr/the/les-grandes-familles/rooibos.html",
    },
    {
        country: "Chine, Province du Fujian",
        name: "Oolong",
        description: "Entre ciel et terre, le thé Oolong déploie des arômes subtils mêlant douceur florale et rondeur torréfiée, offrant une danse délicate entre fraîcheur végétale et chaleur boisée.",
        id: "CN",
        position: { x: 73, y: 42 },
        image: require("@/assets/tea/Oolong.png"),
        url: "https://www.mariagefreres.com/fr/catalogsearch/result/?q=Oolong",
    },
]

export const useDrinkStore = defineStore("drinkStore", {
    state: () => ({
        drinks: {
            alcool: hardsData,
            cafe: coffeeData,
            the: teaData,
        }
    }),
    getters: {
        // Récupérer une boisson par ID (peu importe le type)
        getDrinkById: (state) => (id) => {
            for (const type in state.drinks) {
                const drink = state.drinks[type].find(d => d.id === id);
                if (drink) return drink;
            }
            return null;
        },

        // Récupérer toutes les boissons d'un type (alcool, café, thé)
        getDrinksByType: (state) => (type) => {
            return state.drinks[type] || [];
        }
    }
});

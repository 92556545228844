import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyBInzptUpdzayNp5DLyQN4elTh1jY_-aPE",
    authDomain: "extra-ordinaire-12b0a.firebaseapp.com",
    projectId: "extra-ordinaire-12b0a",
    storageBucket: "extra-ordinaire-12b0a.firebasestorage.app",
    messagingSenderId: "406259866748",
    appId: "1:406259866748:web:e2abe6339dc15f721bbdf9",
    measurementId: "G-PVMF0Y2NCB"
  };

// Initialisation de Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
<template>
  <div class="game-list">
    <h2>Jeux du Mariage</h2>
    <h3>Extra-ordinaire !</h3>
    <div class="game-whois">
        <p>Que vous soyez messieurs ou mesdames, vous êtes si nombreux à être incroyables. Au-delà de la chance que nous avons de vous avoir à nos côtés, nous voulons le crier haut et fort à tous ceux que nous aimons.</p><br>
        <p>Le système est simple : trouvez une pancarte <strong>"Extra-ordinaire"</strong>dans le domaine et votez avec votre téléphone en scannant le QR code de la personne qui, selon vous, a réalisé ce qui est écrit sur la pancarte !</p><br>
        <p>Curieux de connaître les résultats à l'avance ? Ou peut-être êtes-vous concerné par l'une des pancartes et votre curiosité vous démange ?</p>
        <button @click="GoOnPage('/games/whois')" class="whois_button">
          Nos préjugés
          <div class="whois_button_content">
              <font-awesome-icon class="game-whois_button_content" icon="glasses" />
          </div>
        </button>
    </div>
    <h3>L'esplanade</h3>
    <div class="game-cards" ref="gameCards">
      <div v-for="game in games" :key="game.id" class="game-card"  @click="openModal(game)">
        <button class="game-cards_button">
          <p>{{ game.title }}</p>
        </button>
      </div>
    </div>
    <div v-if="selectedGame" class="modal" @click.self="closeModal">
      <GameDetails :game="selectedGame" @close="closeModal" />
    </div>

  </div>
</template>

<script setup>

import { onMounted, ref, watch } from 'vue';
import { useGamesStore } from '../store/gamesStore';
import { useRoute, useRouter } from 'vue-router';
import gsap from 'gsap';
import GameDetails from '@/components/GameDetailsComponent.vue';

const store = useGamesStore();
const games = store.games;
const title = ref(null);
const gameCards = ref(null);
const selectedGame = ref(null);
const route = useRoute();
const router = useRouter();


const GoOnPage = (path) => {
  router.push(path)
}

const openModal = (game) => {
  selectedGame.value = game;
  console.log(selectedGame)
};

const closeModal = () => {
  selectedGame.value = null;
  const newQuery = { ...route.query };
  delete newQuery.modal;
  delete newQuery.id;
  router.replace({ query: newQuery });
};

const checkDateAndRedirect = () => {
  const targetDate = new Date("2025-04-26T19:00:00");
  const currentDate = new Date();

  if (currentDate < targetDate && !window.localStorage.getItem('admin')) {
    // Redirection vers une page temporaire
    window.location.href = "/tempo";
  }
};


onMounted(() => {
  checkDateAndRedirect();
  if (route.query.modal === "game" && route.query.id) {
    const gameFromURL = store.getGameById(route.query.id);
    if (gameFromURL) {
      selectedGame.value = gameFromURL;
    }
  } else {
      console.warn(`Aucun jeu trouvé avec l'ID : ${route.query.id}`);
    }
  gsap.from(title.value, {
    opacity: 0,
    y: -20,
    duration: 1,
    ease: 'power3.out'
  });

  gsap.from(gameCards.value.children, {
    opacity: 0,
    y: 20,
    duration: 1,
    stagger: 0.2,
    ease: 'power3.out'
  });
});

watch(() => route.query, (newQuery) => {
  if (newQuery.modal === "game" && newQuery.id) {
    const gameFromURL = store.getGameById(newQuery.id);
    if (gameFromURL) {
      selectedGame.value = gameFromURL;
    }
  } else {
    selectedGame.value = null;
  }
});

</script>

<style lang="scss" scoped>
.game-list {
  text-align: center;
  padding: 20px;
}

.game-cards {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20%;
  &_button {
    background-color: transparent;
    border: none;
    p {
      color: $GreenShade1;
      font-size: 1.2em;   
    }

  }
  .game-card {
    background: white;
    padding: 15px;
    border-radius: 12px;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 300px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }

    p {
      font-family: "Sarlotte";
      font-size: 1.2em;
      cursor: pointer;
    }
  }
}

/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.whois_button {
  width: 200px;
  height: 60px;
  margin: 25px auto;
  border-radius: 10px;
  font-family: "Sarlotte";
  font-size: 1.2em;
  transition: all 0.5s ease;
  box-sizing: border-box;
  text-align: center;
  color: rgb(236, 240, 241);
  letter-spacing: 2px;
  padding: 14px 0 14px 0;
  overflow: hidden;
  background: $BrownShade1;
  position: relative;
  border: none;
  &_content {
    position: relative;
    transition: all .2s ease;
    top: 25px;
    &_icone {
      font-size: 1.2em
    }
  }
}
.whois_button:hover .whois_button_content {
        top: 3px;
        cursor: pointer
    }
    .whois_button:hover {
        padding: 5px 0 5px 0;
    }
</style>
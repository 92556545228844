<template>
  <div class="home">
    <!-- Groupe de boutons -->
    <div class="button-group">
      <button class="type coffee" @click="selectDrinkType('cafe')" :class="{ active: selectedType === 'cafe' }">Café</button>
      <button class="type tea" @click="selectDrinkType('the')" :class="{ active: selectedType === 'the' }">Thé</button>
      <button class="type alcool" @click="selectDrinkType('alcool')" :class="{ active: selectedType === 'alcool' }">Alcool</button>
    </div>

    <!-- Carte interactive -->
    <WorldMap @showDetails="showDetails" :selectedType="selectedType" />

    <!-- Vignettes sous la carte -->
    <div class="preview-container" v-if="filteredDrinks.length">
      <div v-for="drink in filteredDrinks" :key="drink.id" class="preview-card" @click="showDetails(drink)">
        <img :src="drink.image" :alt="drink.name" />
        <h3>{{ drink.name }}</h3>
        <p>{{ drink.country }}</p>
      </div>
    </div>

    <!-- Détails de la boisson -->
    <DrinkDetailsComponent
      v-if="selectedDrink"
      :drink="selectedDrink"
      :theme="selectedType"
      @close="closeModal"
      @CheckAndRedirect="checkDateAndRedirect"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useDrinkStore } from "@/store/drinkStore";
import WorldMap from "@/components/WorldMapComponent.vue";
import DrinkDetailsComponent from "@/components/DrinksDetailsComponent.vue";
import { gsap } from "gsap";

const route = useRoute();
const router = useRouter();
const store = useDrinkStore();
const selectedDrink = ref(null);
const selectedType = ref("alcool");

// Récupération des boissons en fonction du type sélectionné
const filteredDrinks = computed(() => store.getDrinksByType(selectedType.value));

const selectDrinkType = (type) => {
  selectedType.value = type;
  selectedDrink.value = null;
};

const showDetails = (drink) => {
  selectedDrink.value = drink;
  // Mise à jour de l'URL avec l'ID du drink
  router.replace({ query: { modal: "drink", id: drink.id } });
};

// Fonction pour fermer le modal et nettoyer l'URL
const closeModal = () => {
  selectedDrink.value = null;
  // Retirer les paramètres `modal` et `id` de l'URL
  const newQuery = { ...route.query };
  delete newQuery.modal;
  delete newQuery.id;
  router.replace({ query: newQuery });
};

// Vérification de la date avant d'afficher la page (protection)
const checkDateAndRedirect = () => {
  const targetDate = new Date("2025-04-26T19:00:00");
  const currentDate = new Date();

  if (currentDate < targetDate && !window.localStorage.getItem('admin')) {
    // Redirection vers une page temporaire
    window.location.href = "/tempo";
  }
};



// Animation au montage
onMounted(() => {
  checkDateAndRedirect();
  if (route.query.modal === "drink" && route.query.id) {
    const drinkFromURL = store.getDrinkById(route.query.id);
    if (drinkFromURL) {
      selectedDrink.value = drinkFromURL;
    }
  }
  gsap.fromTo(
    ".button-group button",
    { opacity: 0, y: -20 },
    { opacity: 1, y: 0, duration: 1, stagger: 0.2, ease: "power2.out" }
  );
});

watch(() => route.query, (newQuery) => {
  if (newQuery.modal === "drink" && newQuery.id) {
    const drinkFromURL = store.getDrinkById(newQuery.id);
    if (drinkFromURL) {
      selectedDrink.value = drinkFromURL;
    }
  } else {
    selectedDrink.value = null;
  }
});
</script>

<style lang="scss">
// 🎨 Définition des couleurs principales
$coffee: #6d3a1a;
$tea: #164f16;
$alcool: #f47777;
$border-color: #e6ccb2;
$text-color: black;
$white: white;

// 🎨 Mixin pour gérer les styles des boutons actifs et au survol
@mixin button-style($color) {
  border-color: $color;

  &:hover,
  &.active {
    background-color: $color;
    color: $white;
    font-weight: bold;
    border-width: 2px;
  }
}

// 🎯 Styles généraux de la page
.home {
  align-items: center;
  margin-bottom: 150px;
}

// 🔘 Groupe de boutons (café, thé, alcool)
.button-group {
  display: flex;
  justify-content: space-evenly;
  width: 90%;
  max-width: 1000px;
  margin: 15% auto 50px auto;

  .type {
    display: inline-block;
    padding: 12px 24px;
    border: 1px solid $border-color;
    border-radius: 4px;
    transition: 0.2s;
    font-size: 19px;
    cursor: pointer;
    background-color: transparent;
    transform: translateY(-100px);
    opacity: 0;
    color: $text-color;

    &.active {
      font-weight: bold;
      border-width: 2px;
    }
  }

  // 🏆 Application des mixins pour chaque type de boisson
  .coffee {
    @include button-style($coffee);
  }
  
  .tea {
    @include button-style($tea);
  }

  .alcool {
    @include button-style($alcool);
  }
}

// 🎴 Conteneur des vignettes sous la carte
.preview-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 20px;
  margin-bottom: 150px;
}

// 🎴 Carte individuelle pour chaque boisson
.preview-card {
  width: 150px;
  padding: 10px;
  text-align: center;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  img {
    width: 125px;
    height: 125px;
    object-fit: cover;
    border-radius: 6px;
  }

  h3 {
    font-size: 1rem;
    margin-top: 5px;
  }

  p {
    font-size: 0.9rem;
    color: #666;
  }
}

</style>
<template>
    <div class="whois-display">
        <button class="back-btn" @click="router.push('/games/whois')">Retour</button>

        <p class="intro-text">{{ randomIntro }}</p>
        <h4 class="game-phrase">{{ game.phrase }}</h4>

        <div class="candidates">
            <div v-for="candidat in game.candidats" :key="candidat.id" class="candidate">
                <img :src="getImagePath(candidat.name)" :alt="candidat.name" class="candidate-img" />
                <p class="candidate-name">{{ candidat.name }}</p>
                <p class="votes">
                    <span class="vote-number">{{ candidat.score }}</span> Votes
                </p>
            </div>
        </div>
        <div v-if="alreadyVoted" class="modal-overlay" @click.self="alreadyVoted = false">
            <div class="modal-content">
                <h3>👮‍♂️</h3>
                <p>Serieusement t'as cru j'avais pas anticipé ?</p>
                <button @click="alreadyVoted = false"><p>Ok laisse moi voir les scores ...</p></button>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { db } from "@/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

const route = useRoute();
const router = useRouter();
const game = ref({ phrase: "", candidats: [] });
const gameId = route.params.id || "pancarte_1"; // Récupère l’ID depuis l’URL
const alreadyVoted = ref(false);

const introPhrases = [
    "Encore quelqu’un qui sort du commun !",
    "Une personne d'exception mérite d'être reconnue !",
    "Un talent caché que l'on va dévoiler aujourd'hui !",
    "Prépare-toi à être impressionné par cet exploit !",
    "Certains sont extraordinaires, et cette personne en fait partie !",
    "Qui aurait cru que ce défi était réalisable ?",
    "N'est-ce pas incroyable ?!",
    "Le talent se cache souvent là où on ne l'attend pas.",
    "Voilà quelqu’un qui ne recule devant rien !"
];

// 🔹 Générer une phrase aléatoire au chargement
const randomIntro = computed(() => {
    const index = Math.floor(Math.random() * introPhrases.length);
    return introPhrases[index];
});

// 📌 Récupérer les données Firebase
async function fetchGame() {
    const gameRef = doc(db, "Qui-est-ce", gameId);
    const snapshot = await getDoc(gameRef);
    if (snapshot.exists()) {
        game.value = snapshot.data();
        checkForVote(); // Vérifier si un vote doit être ajouté
    }
}

// 📌 Vérifier si un vote est demandé dans l'URL
async function checkForVote() {
    if ("vote" in route.query && "candidat" in route.query) {
        const candidatId = parseInt(route.query.candidat, 10);
        if (!isNaN(candidatId)) {
            await voteForCandidat(candidatId);
        }
    }
}

// 📌 Ajouter un vote à un candidat par ID
async function voteForCandidat(candidatId) {
    const gameRef = doc(db, "Qui-est-ce", gameId);
    const snapshot = await getDoc(gameRef);

    // 🔹 Récupère les votes déjà effectués depuis le localStorage
    const votedPancartes = JSON.parse(localStorage.getItem("votedPancartes")) || [];

    // 🔹 Vérifie si l'utilisateur a déjà voté pour cette pancarte
    if (votedPancartes.includes(gameId)) {
        alreadyVoted.value = true; // ✅ Afficher le modal UX
        router.replace({ query: {} }); // ✅ Nettoyer l'URL
        return; // ⛔ Empêche le vote
    }

    if (snapshot.exists()) {
        let data = snapshot.data();
        let candidats = data.candidats.map(candidat =>
            candidat.id === candidatId 
                ? { ...candidat, score: (parseInt(candidat.score) || 0) + 1 } 
                : candidat
        );

        await updateDoc(gameRef, { candidats });
        game.value.candidats = candidats; // Mise à jour locale

        // 🔹 Ajoute l'ID de la pancarte aux votes déjà effectués
        votedPancartes.push(gameId);
        localStorage.setItem("votedPancartes", JSON.stringify(votedPancartes));

        router.replace({ query: {} }); // ✅ Nettoyer l'URL après le vote
    }
}

// 📌 Fonction pour récupérer l'image stockée en local
const getImagePath = (name) => {
    try {
        const formattedName = name.replace(/\s+/g, "_");
        return require(`@/assets/whois/${formattedName}.jpg`);
    } catch (error) {
        console.warn(`Image non trouvée pour ${name}, utilisation d'une image par défaut.`);
        return require("@/assets/whois/default.jpg"); // Image par défaut si non trouvée
    }
};

// 📌 Watcher pour détecter un vote via l’URL
watch(() => route.query, () => {
    checkForVote();
});

onMounted(() => {
    fetchGame();
});
</script>

<style lang="scss" scoped>
// 🎨 Variables de couleurs
$primary-color: $BrownShade1;
$secondary-color: $GreenShade1;
$background-color: rgba(255, 255, 255, 0.7);

// 🏔️ Conteneur principal
.whois-display {
    text-align: center;
    padding: 20px;
}

// 🔙 Bouton retour
.back-btn {
    background: $primary-color;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 1em;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
        background: darken($primary-color, 10%);
        transform: scale(1.05);
    }
}

// ✨ Texte d'intro
.intro-text {
    font-size: 1.1em;
    font-weight: bold;
    margin-top: 20px;
}

// 📝 Phrase du jeu
.game-phrase {
    font-family: "Sarlotte", cursive;
    font-size: 1.5em;
    color: $secondary-color;
    font-style: italic;
}

// 🏅 Candidats
.candidates {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
}

// 🎭 Carte d'un candidat
.candidate {
    background: $background-color;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s ease-in-out;

    &:hover {
        transform: scale(1.1);
    }
}

// 📷 Image du candidat
.candidate-img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
}

// 🏆 Nom & votes
.candidate-name {
    font-size: 1em;
    font-weight: bold;
    margin-top: 5px;
}

.votes {
    font-size: 0.9em;
    color: $secondary-color;
    margin-top: 5px;
    font-style: italic;
}

.vote-number {
    font-weight: bold;
    font-size: 1.2em;
}

// 🎨 Modal overlay
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

// 📝 Contenu du modal
.modal-content {
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 10px;
  text-align: center;
  width: 350px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s ease-out;
}

// 🎀 Animation d'apparition
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

// 🔘 Bouton de confirmation
.modal-content button {
  background: #6d3a1a;
  
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 1em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 15px;
  &:hover {
    background: darken(#6d3a1a, 10%);
    transform: scale(1.05);
  }
  p {
    color: white;
  }
}

</style>

import { defineStore } from 'pinia';

export const useGamesStore = defineStore('games', {
  state: () => ({
    games: [
        { 
            id: 1, 
            title: 'Laisseras-tu les frères tomber ?', 
            storytelling: [
                "Le vent cingle ton visage alors que tu plantes tes crampons dans la glace. L’air est mince, chaque respiration est une victoire. Tu es presque au sommet du Grand Paradiso, l’un des plus hauts sommets d’Italie. Mais soudain, une fissure s’ouvre sous vos pieds…",
                "La corde qui vous relie se tend brutalement. Tes compagnons de cordée glissent vers la crevasse. Leurs cris résonnent dans l’immensité blanche. Tiendras-tu bon ?",
                "Un seul camp l’emportera. Reste à savoir si tu seras celui qui hisse… ou celui qui chute."
            ],
            rules: ['Deux équipes égale', 'Le premiere équipe qui mets un pieds dans la zone morte a perdue !'],
        },
        { 
            id: 2, 
            title: 'Chemin des Senteurs', 
            storytelling: [
                "Ferme les yeux. Inspire profondément. Tu es entré dans l’univers fascinant du nez, où chaque arôme raconte une histoire et chaque fragrance compose une harmonie secrète.",
                "Dans cette épreuve, tes seuls outils seront ton nez et ta mémoire. Identifie, associe, devine. Chaque senteur porte un indice… à toi de décrypter le message invisible de l’odorat.",
            ],
            rules: ["Prendre son temps pour reconnaitre les différentes odeurs", "Noté ses conclusions dans le 'Perfume notes'"],
        },
        { 
            id: 3, 
            title: 'Faut cogite fast !', 
            storytelling: [
                "Ici, chaque seconde est une ressource précieuse. Pas de retour en arrière, pas de pause pour réfléchir trop longtemps. Il faut voir, décider, et agir. Un instant d’hésitation et l’adversaire en profitera.",
                "Le pire ? Le chrono n’est même pas à côté. Il est là-bas, un peu trop loin, t’obligeant à calculer en permanence. Combien de temps reste-t-il ? As-tu encore une minute ? Dix secondes ? Tu n’as pas le luxe de vérifier à chaque coup.",
                "Jouer vite, jouer bien. Lequel de ces deux objectifs sacrifieras-tu en premier ?"
            ],
            rules: ["Partie en blind ! 5 minutes max à deux", "Defaite à l'échec et mat, au chrono et à l'abandon"],
        },
        { 
            id: 4, 
            title: 'Le parcours du Barista', 
            storytelling: [
                "Derrière le comptoir, c’est l’effervescence. Les commandes s’enchaînent, les clients s’impatientent, le temps presse. Un service parfait, ce n’est pas juste une question de goût… c’est un ballet précis entre vitesse, équilibre et maîtrise.",
                "À toi de slalomer entre les obstacles, de gérer la pression, et de livrer chaque commande sans une seule éclaboussure. Thé ou café, peu importe… seul le service parfait compte.",
                "Dernière table en vue… Alors, champion du barista, tiendras-tu jusqu’à la dernière goutte ?"
            ],
            rules: ["Trouver un adversaire", "Remplir avec un commande au hasard", "Remplir le plateau", "Arriver le premier avec le plus de verres"]
        },
        { 
            id: 5, 
            title: 'Pepites du Grenier', 
            storytelling: [
                "Dans un coin poussiéreux du grenier, un carton oublié, marqué d’un vieux logo Nintendo. En soulevant le couvercle, le trésor apparaît : des consoles aux plastiques jaunis par le temps, des cartouches à souffler, des notices en parfait état… un véritable trésor du rétrogaming.",
                "Les seuls indices dont tu disposes ? Les panneaux explicatifs à côté de chaque lot. Année de sortie, titres emblématiques, éditions rares… tout est là, mais sauras-tu estimer leur véritable valeur ?",
                "Les collectionneurs le savent bien : certaines consoles valent une fortune, d’autres ont perdu leur magie avec le temps. C’est une question de flair, d’instinct… et d’un peu de chance.",
            ],
            rules: ["Interdiction de toucher", "Estime le prix de chaque lots", "Ecris ton nom et le détails sur une fiche estimation", "Mettre dans l'urne"]
        },
        { 
            id: 6, 
            title: 'Ça se règle sur le terrain !', 
            storytelling: [
                "Pas de palabres, pas d’excuses… ici, seule la maîtrise compte.",
                "Le baby-foot est en place, les équipes se forment, les mains se crispent sur les barres. Dès que la balle est lâchée, c’est fini les politesses : réflexes, rapidité et précision feront la loi.",
                "À la fin, il n’y aura qu’un gagnant. Alors… t’es prêt à régler ça sur le terrain ?"
            ],
            rules: ["En solo ou en équipe", "10/0 C'est sous le baby", "10/-1 c'est ... on est a un mariage quand même ..."]
        },
        { 
            id: 7, 
            title: 'Énigme Insulaire', 
            storytelling: [
                "Bienvenue sur mon île. Ici, je travaille d’ordinaire avec Tom Nook, le maire bien connu de cette paisible enclave insulaire. Mais ce matin, en arrivant… quelque chose cloche.",
                "Les maisons sont en désordre. Des objets ont été déplacés, des fruits échangés, et plus étrange encore… les habitants semblent avoir oublié où ils habitent !",
                "À toi de mener l’enquête. Qui vit où ? Quel est leur fruit préféré ? Quels objets appartiennent à quel villageois ?",
                "Croise les informations, déduis les bonnes associations… et restaure l’ordre sur l’île avant que Tom Nook ne s’en mêle !"
            ],
            rules: ["Pur logique", "Inspiré de l'enigme d'Einstein ou des 5 maisons", "Note ta réponse sur la fiche réponse 😉"]
        },
    ]
  }),
  getters: {
    getGameById: (state) => (id) => state.games.find(game => game.id == id)
  }
});
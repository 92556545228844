<template>
    <div class="whois-container">
      <h3 class="title">Les pancartes Extra-Ordinaire !</h3>
  
      <!-- 📌 Liste des pancartes -->
      <h4>Pour une fois qu'il est bon d'avoir des préjugés !</h4>
      <div v-if="pancartes.length && !selectedGame" class="pancarte-list">
        <div 
          v-for="pancarte in pancartes" 
          :key="pancarte.id" 
          class="pancarte-card"
          @click="goToPancarte(pancarte.id)"
        >
          <h4 class="pancarte-title">{{ pancarte.phrase }}</h4>
        </div>
      </div>
  
      <!-- 📌 Affichage du détail de la pancarte sélectionnée -->
      <ExtraOrdinaireComponent v-if="selectedGame" :game="selectedGame" @close="goBack" />
  
      <p v-if="loading" class="loading-text">Chargement...</p>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, watch } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { db } from "@/firebase";
  import { collection, getDocs, doc, getDoc } from "firebase/firestore";
  import ExtraOrdinaireComponent from "@/components/ExtraOrdinaireComponent.vue";
  
  const route = useRoute();
  const router = useRouter();
  const pancartes = ref([]);
  const selectedGame = ref(null);
  const loading = ref(true);
  
  // 📌 Récupérer toutes les pancartes depuis Firestore
  async function fetchPancartes() {
    try {
      const querySnapshot = await getDocs(collection(db, "Qui-est-ce"));
      pancartes.value = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      // Vérifier si un ID est déjà dans l’URL et charger la pancarte
      if (route.params.id) {
        fetchPancarteDetails(route.params.id);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des pancartes :", error);
    } finally {
      loading.value = false;
    }
  }
  
  // 📌 Charger les détails d'une pancarte spécifique
  async function fetchPancarteDetails(id) {
    try {
      const gameRef = doc(db, "Qui-est-ce", id);
      const snapshot = await getDoc(gameRef);
      if (snapshot.exists()) {
        selectedGame.value = snapshot.data();
      } else {
        console.warn("Pancarte introuvable !");
        router.push("/games/whois"); // Redirige si l’ID est invalide
      }
    } catch (error) {
      console.error("Erreur lors du chargement de la pancarte :", error);
    }
  }
  
  // 📌 Aller à une pancarte spécifique via URL
  function goToPancarte(id) {
    router.push({ name: "WhoIsDetail", params: { id } });
  }
  
  // 📌 Fermer la pancarte et revenir à la liste
  function goBack() {
    selectedGame.value = null;
    router.push({ name: "WhoIs" });
  }
  
  // 📌 Détecter les changements d’ID dans l’URL
  watch(() => route.params.id, (newId) => {
    if (newId) {
      fetchPancarteDetails(newId);
    } else {
      selectedGame.value = null;
    }
  });
  
  onMounted(fetchPancartes);
  </script>
  
  <style lang="scss" scoped>

  .whois-container {
    text-align: center;
    padding: 20px;
    margin-bottom: 20%;
  }
  
  .title {
    color: $GreenShade1;
    margin-bottom: 20px;
  }
  
  // 📜 Liste des pancartes
  .pancarte-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  // 📌 Carte d'une pancarte
  .pancarte-card {
    background: white;
    padding: 15px;
    border-radius: 12px;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    width: 250px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
  
    &:hover {
      transform: scale(1.05);
    }
  
    .pancarte-title {
      font-size: 1.2em;
      color: $BrownShade1;
      margin-bottom: 10px;
    }
  }
  
  .loading-text {
    font-size: 1.5em;
    color: $BrownShade1;
    text-align: center;
    font-style: italic;
  }

  h4 {
    color: $BrownShade1;
  }

  </style>
  
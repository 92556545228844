<template>
    <div class="tempo">
        <p>C'est peut être pas encore le bon moment ?</p>
        <p>Repasse plus tard ! Ou si tu t'aimes l'IT tu devrais pouvoir contourner mon systeme de sécurité facilement 😉</p>
        <p class="hiden"> Commence par un petit F5 ou F12</p>
        <p>Enjoy !</p>
        <br>
        <h4><a @click="this.$router.push('/')">Retour au site du faire-part</a></h4>
    </div>
</template>

<script>

</script>

<style lang="scss">

.tempo {
    width: 90%;
    text-align: center;
    margin: 10% auto;
    .hiden {
        color: white
    }
    a {
        text-decoration: none;
        color: $GreenShade1;
        border: 1px $BrownShade1 solid;
        font-size: 1em;
        padding: 15px;
        border-radius: 15px;
        cursor: pointer;
    }

}

</style>
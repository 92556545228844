<template>
  <div class="map-container">
    <!-- Carte SVG -->
    <svg
      v-html="worldMap"
      class="world-map"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1009.6727 665.96301"
      preserveAspectRatio="xMidYMid meet"
      width="85%"
    ></svg>

    <!-- Conteneur des vignettes -->
    <div class="vignettes-container">
      <div
        v-for="drink in filteredDrinks"
        :key="drink.id"
        class="vignette"
        :style="{ top: drink.position.y + '%', left: drink.position.x + '%' }"
        @click="showDetails(drink)"
      >
        <img :src="drink.image" :alt="drink.name" />
        <p>{{ drink.name }}</p>
      </div>
    </div>

    <!-- Modale pour afficher les détails -->
    <DrinkDetailsComponent 
      v-if="selectedDrink"
      :drink="selectedDrink"
      :theme="selectedType"
      @close="selectedDrink = null"
    />
  </div>
</template>

<script setup>

import worldMap from "@/assets/worldmap.svg";
import { ref, computed, watch, onMounted } from "vue";
import { useDrinkStore } from "@/store/drinkStore";
import DrinkDetailsComponent from "@/components/DrinksDetailsComponent.vue";

const emit = defineEmits(["CheckAndRedirect"]);
const props = defineProps({
  selectedType: {
    type: String,
    required: true,
  },
});

const store = useDrinkStore();
const selectedDrink = ref(null);

// Récupération des boissons selon le type sélectionné
const filteredDrinks = computed(() => store.getDrinksByType(props.selectedType));

const showDetails = (drink) => {
  selectedDrink.value = drink;
};

// Gestion des couleurs de la carte
const resetCountryColors = () => {
  Object.values(store.drinks).flat().forEach((drink) => {
    let el = document.getElementById(drink.id);
    if (el) {
      el.style.fill = "#6D9362";
    }
  });
};

const updateCountryColors = () => {
  resetCountryColors();
  const colorMap = {
    alcool: "#f47777",
    cafe: "#6d3a1a",
    the: "#164f16",
  };

  filteredDrinks.value.forEach((drink) => {
    let el = document.getElementById(drink.id);
    if (el) {
      el.style.fill = colorMap[props.selectedType];
    }
  });
};



watch(() => props.selectedType, updateCountryColors);

onMounted(() => {
  emit("CheckAndRedirect");
  updateCountryColors();
});

</script>

<style lang="scss">
.map-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.world-map {
  width: 90%;
  height: auto;
  path {
    fill: #6D9362;
  }
}

.vignettes-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.vignette {
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.vignette:hover {
  transform: scale(1.2) translate(-50%, -50%);
}

.vignette img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #333;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.vignette p {
  margin-top: 5px;
  font-size: 1em;
  text-align: center;
  color: #333;
}

@media (max-width: 768px) {
  .vignette img {
    width: 40px;
    height: 40px;
  }
  .vignette p {
    font-size: 15px;
  }
}
</style>

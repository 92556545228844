<template>
  <div v-if="game" class="game-overlay" @click.self="$emit('close')">
    <div class="game-details" ref="details">
      <button class="close-btn" @click="emit('close')">✖</button>
      <h2>{{ game.title }}</h2>

      <div class="rules">
        <h3>Règles</h3>
        <ul>
          <li v-for="(rule, index) in game.rules" :key="index">{{ rule }}</li>
        </ul>
      </div>

      <div class="storytelling">
        <h3>Storytelling</h3>
        <p v-for="(line, index) in game.storytelling" :key="index">{{ line }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import gsap from "gsap";

defineProps({
  game: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["close"]);
const details = ref(null);

// Animation d'apparition du modal
onMounted(() => {
  if (details.value) {
    gsap.from(details.value, {
      opacity: 0,
      y: 30,
      duration: 1,
      ease: "power3.out",
    });
  }
});
</script>

<style lang="scss" scoped>
.game-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.game-details {
  background: white;
  text-align: center;
  padding: 20px;
  max-width: 600px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  position: relative;
}

h2 {
  color: #8b4513;
}

h3 {
  color: #6b8e23;
  text-align: center;
  margin: 1em;
}

ul {
  list-style: none;
  padding: 0;
}

.storytelling, .rules {
  text-align: center;
  margin-top: 15px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;

  &:hover {
    color: red;
  }
}
</style>

<template>
  <div class="modal-overlay" @click.self="$emit('close')">
    <div :class="['modal-content', themeClass]">
      <button class="close-btn" @click="closeModal">✖</button>
      <div class="modal-content_header">
        <img :src="drink.image" :alt="drink.name" />
        <h2>{{ drink.name }}</h2>
      </div>
      <p><strong>Origine:</strong> {{ drink.country }}</p>
      <p v-if="drink.type"><strong>Type :</strong> {{ drink.type }}, {{ drink.alcool }}%</p>
      <div class="modal-content_description">
        <h4>En quelques mots :</h4>
        <p>{{ drink.description }}</p>        
      </div>
      <div class="modal-content_link">
        <h4>T'as kiffé ?</h4>
        <p v-if="drink.url">Tu peux en acheter <a :href="drink.url" target="_blank" rel="noopener noreferrer">ici</a></p>
      </div>
    </div>
  </div>
</template>

<script setup>

import { gsap } from "gsap";
import { computed } from "vue";

const props = defineProps({
  drink: {
    type: Object,
    required: true
  },
  theme: {
    type: String,
    required: true
  }
});

const themeClass = computed(() => {
  return {
    cafe: "theme-cafe",
    the: "theme-the",
    alcool: "theme-alcool"
  }[props.theme] || "theme-alcool";
});

const closeModal = () => {
  gsap.to(".modal-content", {
    scale: 0.8,
    opacity: 0,
    duration: 0.3,
    ease: "power2.in",
    onComplete: () => emit("close")
  });
};

const emit = defineEmits(["close"]);

gsap.fromTo(".modal-content", { scale: 0.8, opacity: 0 }, { scale: 1, opacity: 1, duration: 0.5, ease: "power2.out" });
</script>

<style lang="scss" scoped>
// Variables
$modal-bg: #fff;
$shadow-color: rgba(0, 0, 0, 0.3);
$close-btn-hover: #d32f2f;

// Overlay du modal
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

// Contenu du modal
.modal-content {
  background-color: $modal-bg;
  border-radius: 10px;
  text-align: center;
  width: 90%;
  max-width: 500px; // Ajustable
  max-height: 80vh; // Limite la hauteur
  overflow-y: auto; // Active le scroll si nécessaire
  padding: 20px;
  box-shadow: 0px 5px 15px $shadow-color;
  position: relative;

  &_header {
    img {
      max-width: 100%;
      max-height: 250px;
      width: auto;
      height: auto;
      display: block;
      margin: 0 auto;
      border-radius: 8px;
      object-fit: contain;
    }

    h2 {
      margin: 10px 0;
      font-size: 1.5rem;
    }
  }

  p {
    font-size: 1rem;
    color: #444;
    margin-bottom: 10px;
  }

  &_description,
  &_link {
    margin-top: 15px;
    h4 {
      margin-bottom: 5px;
    }
  }
}

// Bouton de fermeture
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;

  &:hover {
    color: $close-btn-hover;
  }
}

// Thèmes
.theme-alcool {
  background-color: #f5f5f5;
  color: #333;
}

.theme-cafe {
  background-color: #d2b48c;
  color: #4b2e2e;
}

.theme-the {
  background-color: #cfe9cf;
  color: #355e3b;
}

</style>
